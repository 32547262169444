var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-3",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"text-h4"},[_vm._v(" Role Management "),_c('v-spacer'),_c('addNewRole')],1)],1),_c('v-card',{staticClass:"px-3 mt-3",attrs:{"elevation":"0"}},[_c('v-card-title',[(_vm.userRoles.length <= 1)?_c('span',[_vm._v(_vm._s(_vm.userRoles.length)+" Role")]):_c('span',[_vm._v(_vm._s(_vm.userRoles.length)+" Roles")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search User Role by Name","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.getHeaders,"items":_vm.userRole,"search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.userCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.userCount)+" ")]}},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dateCreated))+" ")]}},{key:"item.lastUpdated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.lastUpdated))+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("trimLength")(item.description))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('editAndDeleteRole',{attrs:{"roleIndex":item}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }